import { TitleCasePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import { combineLatest, forkJoin, iif, Observable, of, Subject, Subscription } from 'rxjs';
import { filter, first, map, mergeMap, shareReplay } from 'rxjs/operators';

import { SelectItem } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Sidebar } from 'primeng/sidebar';

import * as Bowser from 'bowser';

import { environment } from '@environment';
import { Environment, MetaInfoService, MetaName, Network, Portal, WebComponent } from '@sdpp-web/portal';
import { TelemetryComponent, TelemetryService } from '@sdpp-web/telemetry';
import { TranslateService } from '@sdpp-web/translate';
import { ErrorDisplayService } from '@sdpp-web/ui';
import {
  IUserImpersonationService,
  SessionState,
  Theme,
  User,
  USER_IMPERSONATION_SERVICE,
  UserContextService,
  UserSessionService
} from '@sdpp-web/user';
import { assert } from '@sdpp-web/util';

import { META_CODE } from '../../../environments/ienvironment';
import { CSS_SCOPE_ID, ErrorMessage, HeaderLink, PortalSettingsVisibility, UserChatbotResponse } from '../../models';
import { HeaderIoService } from '../../services';
import { NotificationToastPosition } from '../notification/models/enums';
import { NotificationService } from '../notification/services';
import { DISCLAIMER_TEXT } from '../..//models/constants/chatbot-disclaimer';

@TelemetryComponent(['ngOnInit', 'ngAfterViewInit'])
@Component({
  encapsulation: ViewEncapsulation.None,
  providers: [TitleCasePipe],
  selector: HeaderComponent.SELECTOR,
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnChanges {
  // appcode of the application
  @Input()
  public appcode: string;

  // should show portal settings. Value is PortalSettingsVisibility as JSON string.
  @Input()
  public portalSettings: string;

  // should disable user session check
  @Input()
  public disableUserSessionCheck: string = 'false';

  @Input()
  public showWarningHeader: string = 'false';

  @Input()
  public warningHeaderRight: string = null;

  // id is set to enforce CSS rules
  @HostBinding('attr.id')
  public id: string = CSS_SCOPE_ID;

  @ViewChild('userImpersonation')
  public userImpersonation: OverlayPanel;

  @ViewChild('chatBotSideBar')
  public chatBotSideBar: Sidebar;

  public static readonly SELECTOR: string = 's-header';

  public readonly Portal: typeof Portal = Portal;
  public readonly portal: Portal;
  public readonly deployUrlHost: string;

  public showUserSettings: boolean;
  public portalSettingsVisibility: PortalSettingsVisibility;
  public user$: Observable<User>;
  public authenticatedUser$: Observable<User>;
  public users$: Observable<Users>;
  public availableThemes$: Observable<Theme[]>;
  public currentTheme$: Observable<Theme>;
  public hasCondensedBlotterView$: Observable<boolean>;
  public bnppConnectHeaderLink$: Observable<HeaderLink>;
  public notificationEnabled$: Observable<boolean>;
  public notificationSound$: Observable<boolean>;
  public notificationToastPositions: SelectItem[] = [
    { label: 'Top Right', value: NotificationToastPosition.TopRight },
    { label: 'Top Left', value: NotificationToastPosition.TopLeft },
    { label: 'Bottom Right', value: NotificationToastPosition.BottomRight },
    { label: 'Bottom Left', value: NotificationToastPosition.BottomLeft },
    { label: 'Top Center', value: NotificationToastPosition.TopCenter },
    { label: 'Bottom Center', value: NotificationToastPosition.BottomCenter },
    { label: 'Center', value: NotificationToastPosition.Center }
  ];
  public notificationToastPosition$: Observable<NotificationToastPosition>;

  public areSettingsInitialized$: Observable<boolean>;

  public suggestedUsers$: Observable<Partial<User>[]>;
  public userSearchCriteria$: Subject<string> = new Subject<string>();

  public environment: Environment;
  public Environment: typeof Environment = Environment;
  public showWarningHeader$: Observable<Boolean>;

  public impersonationUserUid: string;
  public formUser: { ssoId: string };

  public isChatbotEnabled$: Observable<boolean>;
  public needDisclaimer: boolean;
  public sidebarVisible: boolean;
  public chatInputValue: string;
  public chatInputOutputMap: { isChatbot: boolean; msg: string }[] = [];
  public isChatbotTyping: boolean;
  public isSideBarMaximized: boolean = false;

  private _sessionSubcription: Subscription;

  constructor(
    private readonly _titleCasePipe: TitleCasePipe,
    private readonly _metaInfoService: MetaInfoService,
    private readonly _userContextService: UserContextService,
    private readonly _headerIoService: HeaderIoService,
    private readonly _userSessionService: UserSessionService,
    private readonly _translateService: TranslateService,
    private readonly _errorDisplayService: ErrorDisplayService,
    private readonly _telemetryService: TelemetryService,
    private readonly _notificationService: NotificationService,
    @Inject(USER_IMPERSONATION_SERVICE) private readonly _userImpersonationService: IUserImpersonationService,
    public elementRef: ElementRef
  ) {
    this.portal = <Portal>this._metaInfoService.getMeta(MetaName.Portal);
    this._errorDisplayService.configure({
      supportEmailAddress: null,
      supportEmailSubject: null,
      appendTo: elementRef.nativeElement,
      baseZIndex: 200000
    });
    this.deployUrlHost = this._metaInfoService.getMetaForWebComponent(META_CODE, WebComponent.DeployUrl);
    this.impersonationUserUid = this._userImpersonationService.getImpersonatedIdentity();
    this.formUser = !!this.impersonationUserUid ? { ssoId: this.impersonationUserUid } : null;
    this.environment = <Environment>this._metaInfoService.getMeta(MetaName.Environment);

    this.initializeChatbot();
  }

  public ngOnInit(): void {
    // inputs are undefined when HeaderComponent is used as web component, use ngOnChanges hook instead
    setTimeout(() => {
      assert(this.appcode, `missing attribute in ${HeaderComponent.SELECTOR}: appcode`);
      this.updateSessionSubscription();
    });

    this.suggestedUsers$ = this.userSearchCriteria$.pipe(
      mergeMap(searchTerm => this._headerIoService.getUsersBySearchTerm(searchTerm)),
      map(res => res.users)
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.portalSettings) {
      this.portalSettingsVisibility = (this.portalSettings && JSON.parse(this.portalSettings)) || {};
      this.updateShowUserSettings();
    }
    if (changes.appcode) {
      this._headerIoService.setAppCode(this.appcode);
      this.initUserContext();
      this.trackUserInformation();
    }
    this.updateSessionSubscription();
  }

  public saveTheme($event: { value: Theme }): void {
    this._headerIoService.saveTheme($event.value.code).subscribe(() => {
      this.refreshPage();
    });
  }

  public saveCondensedView($event: { checked: boolean }): void {
    this._userContextService.setCondensedBlotterView($event.checked).subscribe(() => {
      this.refreshPage();
    });
  }

  public saveNotificationEnabled($event: { checked: boolean }): void {
    this._notificationService.saveNotificationEnabled($event.checked);
  }

  public saveNotificationSound($event: { checked: boolean }): void {
    this._notificationService.saveNotificationSound($event.checked);
  }

  public saveNotificationToastPosition($event: { value: NotificationToastPosition }): void {
    this._notificationService.saveNotificationToastPosition($event.value);
  }

  public isUserImpersonationEnabled(): boolean {
    return environment.userImpersonationConfiguration.enabled;
  }

  public toggleUserImpersonationOverlay($event: MouseEvent): void {
    if (this.isUserImpersonationEnabled()) {
      this.userImpersonation.toggle($event);
    }
  }

  public getImpersonationStyleClasses(): string {
    let styleClasses = 'username';

    if (this.isUserImpersonationEnabled()) {
      styleClasses += ' pointer';
    }

    return styleClasses;
  }

  public setImpersonation(): void {
    this._userImpersonationService.setImpersonatedIdentity(this.formUser.ssoId);
    location.reload();
  }

  public unsetImpersonation(): void {
    this._userImpersonationService.removeImpersonatedIdentity();
    setTimeout(() => {
      location.reload();
    }, 100);
  }

  public getDisplayedUserName(users: Users): string {
    return users.user.ssoId === users.authenticatedUser.ssoId
      ? `${this._titleCasePipe.transform(users.user.firstName)} ${this._titleCasePipe.transform(users.user.lastName)}`
      : `${this._titleCasePipe.transform(users.authenticatedUser.firstName)} (as ${this._titleCasePipe.transform(
          users.user.firstName
        )})`;
  }

  public initializeChatbot(): void {
    this.isChatbotEnabled$ = iif(
      () =>
        this.portal === Portal.SmartDerivatives &&
        <Network>this._metaInfoService.getMeta(MetaName.Network) === Network.Intranet,
      this._headerIoService.isValidUser(),
      of(false)
    );

    this.needDisclaimer = true;
    this.chatInputOutputMap = [{ isChatbot: true, msg: DISCLAIMER_TEXT }];
  }

  public acknowledgeChatbotDisclaimer(): void {
    this.needDisclaimer = false;
    this.chatInputOutputMap = [];
    this.isChatbotTyping = true;
    this._headerIoService.getChatResponse('Hello').subscribe((result: UserChatbotResponse) => {
      this.isChatbotTyping = false;
      this.chatInputOutputMap.push({ isChatbot: true, msg: result.answer });
    });
  }

  public getChatResponse($event: KeyboardEvent = null): void {
    if (this.chatInputValue.length > 0) {
      const inputVal = this.chatInputValue;
      this.chatInputValue = '';
      this.isChatbotTyping = true;
      this.chatInputOutputMap.push({ isChatbot: false, msg: inputVal });
      this._scrollDownChatbotSidebar();

      this._headerIoService.getChatResponse(inputVal).subscribe((result: UserChatbotResponse) => {
        this.isChatbotTyping = false;
        this.chatInputOutputMap.push({ isChatbot: true, msg: result.answer });

        this._scrollDownChatbotSidebar();
      });

      if ($event != null) {
        $event.preventDefault();
      }
    }
  }

  public toggleSidebarSize(): void {
    this.isSideBarMaximized = !this.isSideBarMaximized;
  }

  private updateShowUserSettings(): void {
    this.showUserSettings =
      this.portalSettingsVisibility && Object.values(this.portalSettingsVisibility).some(visible => !!visible);
  }

  private initUserContext(): void {
    const userContextSource = this._userContextService.getUserContext(this.appcode).pipe(shareReplay(1));
    const notificationUserState$ = this._notificationService.getNotificationSettings();

    // user
    this.user$ = userContextSource.pipe(map(context => context.user));
    this.authenticatedUser$ = userContextSource.pipe(map(context => context.authenticatedUser));

    this.users$ = combineLatest([this.user$, this.authenticatedUser$]).pipe(
      map(([user, authenticatedUser]) => ({ user, authenticatedUser }))
    );

    this.showWarningHeader$ = this.user$.pipe(
      map(
        user =>
          environment.enableWarningHeader &&
          this.showWarningHeader === 'true' &&
          this.warningHeaderRight &&
          user.rights.indexOf(this.warningHeaderRight) > -1
      )
    );

    // theme settings
    this.availableThemes$ =
      this.portalSettingsVisibility && this.portalSettingsVisibility.theme
        ? userContextSource.pipe(map(userContext => userContext.availableThemes))
        : of(null);
    this.currentTheme$ =
      this.portalSettingsVisibility && this.portalSettingsVisibility.theme
        ? userContextSource.pipe(map(userContext => userContext.currentTheme))
        : of(null);

    // blotter settings
    this.hasCondensedBlotterView$ =
      this.portalSettingsVisibility && this.portalSettingsVisibility.condensedBlotters
        ? userContextSource.pipe(map(userContext => userContext.hasCondensedBlotterView))
        : of(null);

    // notification settings
    this.notificationEnabled$ = notificationUserState$.pipe(map(state => state.enabled));
    this.notificationSound$ = notificationUserState$.pipe(map(state => state.sound));
    this.notificationToastPosition$ = notificationUserState$.pipe(map(state => state.toastPosition));

    this.bnppConnectHeaderLink$ = this._headerIoService.getHeaderInformation().pipe(
      map(
        headerInfo =>
          headerInfo && headerInfo.link && headerInfo.link.find(link => link.code === environment.bnppConnectAppCode)
      ),
      filter(headerLink => !!headerLink)
    );

    // ready
    this.areSettingsInitialized$ = forkJoin([
      this.availableThemes$,
      this.currentTheme$,
      this.hasCondensedBlotterView$,
      notificationUserState$.pipe(
        filter(state => !!state),
        first()
      )
    ]).pipe(map(() => true));
  }

  private refreshPage(): void {
    location.reload();
  }

  private updateSessionSubscription(): void {
    if (this.disableUserSessionCheck === 'true') {
      if (this._sessionSubcription) {
        this._sessionSubcription.unsubscribe();
        this._sessionSubcription = null;
      }
    } else {
      if (!this._sessionSubcription) {
        this._sessionSubcription = this._userSessionService
          .sessionStateChange$()
          .pipe(filter(sessionState => sessionState === SessionState.CLOSED))
          .subscribe(() => {
            this._errorDisplayService.displayError({
              message: this._translateService.translate(
                ErrorMessage.TRANSLATE_DICTIONARY_NAME,
                ErrorMessage.SessionTimeout
              ),
              needsReload: true
            });
          });
      }
    }
  }

  private trackUserInformation(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const browserInfo: any = Bowser.getParser(window.navigator.userAgent);
    const whitelabel = this._metaInfoService.getMeta(MetaName.Whitelabel);
    const network = this._metaInfoService.getMeta(MetaName.Network) === Network.Intranet ? 'intranet' : 'internet';
    const theme = this._metaInfoService.getMeta(MetaName.Theme);

    combineLatest([this.user$, this.currentTheme$, this.hasCondensedBlotterView$])
      .pipe(
        first(),
        map(([user, currentTheme, hasCondensedBlotter]: [User, Theme, Boolean]) => {
          const { ssoId, email, firstName, lastName, language }: User = user;

          this._telemetryService.message({
            data: {
              appcode: this.appcode,
              user: {
                ssoId,
                email,
                firstName,
                lastName,
                language
              },
              userSettings: {
                currentTheme: currentTheme && currentTheme.code,
                hasCondensedBlotter
              },
              meta: {
                portal: this.portal,
                whitelabel,
                network,
                theme
              },
              location: {
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
              },
              browser: browserInfo.getBrowser(),
              os: browserInfo.getOS(),
              platform: browserInfo.getPlatformType(),
              screenResolution: {
                height: window.screen.height * window.devicePixelRatio,
                width: window.screen.width * window.devicePixelRatio
              },
              windowResolution: {
                height: window.innerHeight,
                width: window.innerWidth
              },
              userAgent: window.navigator.userAgent
            },
            message: 'Sdpp Web User information'
          });
        })
      )
      .subscribe();
  }

  private _scrollDownChatbotSidebar() {
    if (this.chatBotSideBar) {
      //Only scroll content div to last message displayed
      const contentDiv = this.chatBotSideBar.contentTemplate?.elementRef?.nativeElement?.parentElement;
      requestAnimationFrame(() => {
        contentDiv.scrollTo(0, contentDiv.scrollHeight);
      });
    }
  }
}

interface Users {
  user: User;
  authenticatedUser: User;
}
