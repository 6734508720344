import { SignalRConfiguration } from '@sdpp-web/signalr';
import { ChildConfiguration, IConsoleAppender, IHttpAppender, LoggingConfiguration, LogLevel } from '@sdpp-web/logger';
import { UserImpersonationParams } from '@sdpp-web/user';

export interface IEnvironment {
  production: boolean;
  baseSdppApi: string;
  maxNotificationToastNumber: number;
  loggingConfiguration: LoggingConfiguration;
  signalRConfiguration?: SignalRConfiguration;
  bnppConnectAppCode: string;
  telemetryLoggerId: string;
  userImpersonationConfiguration: UserImpersonationParams;
  enableWarningHeader: boolean;
  baseSdppHostApi: string;
}

export const BASE_SDPP_API: string = '/api/sdpp';
export const LEGACY_BASE_SDPP_API: string = '/sdpp/api';
export const BASE_SDPP_HOST_API: string = '/api/host';

/**
 * Name of the telemetry logger.
 */
export const TELEMETRY_LOGGER_ID: string = 'Telemetry';

/**
 * Code for the application when logging.
 */
export const LOG_APPLICATION_CODE: string = 'sdpp-web-host';

/**
 * Code for meta, corresponding to web component code
 */
export const META_CODE: string = 'host';

/**
 * Sdpp Web Code for application BNPP Connect
 */
export const BNPP_CONNECT_APP_CODE: string = 'bnppconnect';

export const PROD_LOGGING_CONFIGURATION: LoggingConfiguration = {
  root: {
    appenders: [
      <IConsoleAppender>{
        id: 'RootConsoleAppender',
        level: LogLevel.WARN,
        type: 'CONSOLE'
      },
      <IHttpAppender>{
        applicationCode: LOG_APPLICATION_CODE,
        id: 'RootHttpAppender',
        level: LogLevel.WARN,
        type: 'HTTP',
        url: '/background/api/sdpp/audit/log/save'
      }
    ],
    level: LogLevel.WARN
  },
  children: [
    <ChildConfiguration>{
      appenders: [
        <IHttpAppender>{
          applicationCode: LOG_APPLICATION_CODE,
          id: `TelemetryHttpAppender`,
          level: LogLevel.INFO,
          type: 'HTTP',
          url: '/background/api/sdpp/audit/telemetry/save'
        }
      ],
      id: TELEMETRY_LOGGER_ID,
      level: LogLevel.INFO
    }
  ]
};
