import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'boldText'
})
export class BoldTextPipe implements PipeTransform {
  constructor(private readonly sanitizer: DomSanitizer) {}

  public transform(value: string): SafeHtml {
    const regex = /(\*\*|__)(?!\s)(.+?)(?<!\s)\1/gim;

    return this.sanitize(this.replace(value, regex));
  }

  private replace(str: string, regex: RegExp) {
    const matched = str.match(regex);

    if (matched) {
      matched.forEach(foundString => {
        const resultString = foundString.substring(2, foundString.length - 2);

        str = str.replace(foundString, `<b>${resultString}</b>`);
      });
    }

    return str;
  }

  private sanitize(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
