<div class="sd-header autoid-s-header" *sLet="showWarningHeader$ | async as showWarningHeader">
  <div class="sd-header-content" [ngClass]="{ warning: showWarningHeader }">
    <div class="banner">
      <div class="logo autoid-whitelabel-logo"></div>
      <ng-container [ngSwitch]="portal">
        <div class="app-name autoid-portal-name brio" *ngSwitchCase="Portal.Brio">
          <div class="logo-brio"></div>
        </div>
        <div class="app-name autoid-portal-name enqdc" *ngSwitchCase="Portal.Enqdc">
          <div class="logo-enqdc"></div>
        </div>
        <div class="app-name autoid-portal-name sdppadmin" *ngSwitchCase="Portal.SdppAdmin">
          <div class="logo-sdppadmin"></div>
        </div>
        <div class="app-name autoid-portal-name cda" *ngSwitchCase="Portal.Cda">
          <div class="logo-cda"></div>
        </div>
        <div class="app-name autoid-portal-name buildit" *ngSwitchCase="Portal.Buildit">
          <div class="logo-buildit"></div>
        </div>
        <div class="app-name autoid-portal-name smartd" *ngSwitchDefault>
          <div class="logo-smartd"></div>
        </div>
      </ng-container>
      <div class="app-additional-title">
        <ng-content select="[title]"></ng-content>
      </div>
      <div class="environment" *ngIf="environment !== Environment.Prod">
        <span class="environment-{{ environment | lowercase }}">({{ environment }})</span>
      </div>
      <div class="separator"></div>
      <div class="toolbar">
        <div class="actions">
          <div
            (click)="sidebarVisible = !sidebarVisible"
            class="action-container chat-svg"
            *ngIf="isChatbotEnabled$ | async"
          >
            <div class="action chatbot-button-icon">
              <img src="{{ deployUrlHost }}/assets/chatbot/robot-icon.svg" alt="ChatBot" class="chatbot-image" />
            </div>
          </div>
        </div>
      </div>
      <div class="bnpp-connect autoid-bnpp-connect" *ngIf="bnppConnectHeaderLink$ | async as bnppConnectHeaderLink">
        <a
          href="{{ bnppConnectHeaderLink.url }}"
          target="{{ bnppConnectHeaderLink.isTargetBlank ? '_blank' : '_self' }}"
        >
          <img src="{{ deployUrlHost }}/assets/bnpp-connect.png" alt="{{ bnppConnectHeaderLink.label }}" />
        </a>
      </div>
      <div
        *ngIf="users$ | async as users"
        #userImpersonationActionContainer
        [class]="getImpersonationStyleClasses()"
        pTooltip="User Impersonation"
        [tooltipDisabled]="!isUserImpersonationEnabled()"
        i18n-pTooltip
        [appendTo]="userImpersonationActionContainer"
        (click)="toggleUserImpersonationOverlay($event)"
      >
        {{ getDisplayedUserName(users) }}
      </div>
      <div class="toolbar">
        <div class="actions">
          <ng-content select="[actions]"></ng-content>
          <div
            #userSettingsActionContainer
            *ngIf="showUserSettings"
            class="action-container autoid-user-settings"
            pTooltip="User Settings"
            i18n-pTooltip
            [appendTo]="userSettingsActionContainer"
            (click)="userSettings.toggle($event)"
          >
            <div class="action action-user sdi sdi-manage"></div>
          </div>
          <s-notification [dialogContainer]="elementRef"></s-notification>
          <div
            #loginActionContainer
            class="action-container autoid-log-off"
            pTooltip="Logout"
            i18n-pTooltip
            [appendTo]="loginActionContainer"
          >
            <a class="action action-login autoid-logout-button sdi sdi-power" href="/logoff"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--
  TODO: REMOVE [appendTo]="null" WHEN FIXED BY PRIMENG
  Currently there is no open ticket in primeng, but a similar was raised for Dropdown: https://github.com/primefaces/primeng/issues/10751
  By default in primeng@12, property appendTo has default 'body' instead of null
-->
<p-overlayPanel #userImpersonation [dismissable]="true" styleClass="user-impersonation-overlay-panel" [appendTo]="null">
  <div class="user-impersonation">
    <div class="portal">
      <fieldset *sLet="suggestedUsers$ | async as suggestions">
        <div class="uid">
          <label i18n>User UID</label>
          <p-autoComplete
            field="ssoId"
            placeholder="type UID, name or email"
            forceSelection="true"
            delay="250"
            minLength="3"
            name="users"
            ngDefaultControl
            [(ngModel)]="formUser"
            [suggestions]="suggestions"
            (completeMethod)="userSearchCriteria$.next($event.query)"
          >
            <ng-template let-user pTemplate="item">
              <span class="pointer">{{ user.firstName }} {{ user.lastName }} ({{ user.ssoId }})</span>
            </ng-template>
          </p-autoComplete>
        </div>
      </fieldset>
      <fieldset>
        <div class="buttons">
          <p-button
            label="Impersonate"
            i18n-label
            [disabled]="!formUser || impersonationUserUid === formUser.ssoId || formUser.ssoId.length === 0"
            (onClick)="setImpersonation()"
          ></p-button>
          <p-button
            class="p-button-secondary"
            label="Reset"
            [disabled]="!impersonationUserUid"
            (onClick)="unsetImpersonation()"
          ></p-button>
        </div>
      </fieldset>
    </div>
  </div>
</p-overlayPanel>

<!--
  TODO: REMOVE [appendTo]="null" WHEN FIXED BY PRIMENG
  Currently there is no open ticket in primeng, but a similar was raised for Dropdown: https://github.com/primefaces/primeng/issues/10751
  By default in primeng@12, property appendTo has default 'body' instead of null
-->
<p-overlayPanel #userSettings [dismissable]="true" styleClass="user-settings-overlay-panel" [appendTo]="null">
  <div class="user-settings">
    <div class="portal" *ngIf="areSettingsInitialized$ | async; else loading">
      <fieldset *ngIf="portalSettingsVisibility?.theme">
        <div class="field">
          <label>Theme</label>
          <p-dropdown
            [options]="availableThemes$ | async"
            [ngModel]="currentTheme$ | async"
            optionLabel="name"
            (onChange)="saveTheme($event)"
          >
          </p-dropdown>
        </div>
      </fieldset>
      <fieldset *ngIf="portalSettingsVisibility?.condensedBlotters">
        <legend>Blotters</legend>
        <div class="field">
          <label>Condensed blotters</label>
          <p-inputSwitch [ngModel]="hasCondensedBlotterView$ | async" (onChange)="saveCondensedView($event)">
          </p-inputSwitch>
        </div>
      </fieldset>
      <fieldset *sLet="notificationEnabled$ | async as notificationEnabled">
        <legend>Notifications</legend>

        <div class="field">
          <label>Enabled</label>
          <p-inputSwitch [ngModel]="notificationEnabled" (onChange)="saveNotificationEnabled($event)"></p-inputSwitch>
        </div>

        <div class="field">
          <label [class.disabled]="!notificationEnabled">Sound</label>
          <p-inputSwitch
            [ngModel]="notificationSound$ | async"
            (onChange)="saveNotificationSound($event)"
            [disabled]="!notificationEnabled"
          >
          </p-inputSwitch>
        </div>

        <div class="field">
          <label [class.disabled]="!notificationEnabled">Toast position</label>
          <p-dropdown
            [options]="notificationToastPositions"
            [ngModel]="notificationToastPosition$ | async"
            (onChange)="saveNotificationToastPosition($event)"
            [disabled]="!notificationEnabled"
          >
          </p-dropdown>
        </div>
      </fieldset>
      <fieldset #appSettingsContainer>
        <ng-content select="[application-settings]"></ng-content>
      </fieldset>
    </div>
  </div>

  <ng-template #loading>
    <div class="loader">
      <div class="wrapper">
        <div class="circle-loader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>

<p-sidebar
  #chatBotSideBar
  [(visible)]="sidebarVisible"
  position="right"
  [showCloseIcon]="false"
  (onHide)="sidebarVisible = false"
  styleClass=" chatbot-sidebar {{ isSideBarMaximized ? 'p-sidebar-lg' : 'p-sidebar-md' }}"
  [modal]="true"
  [dismissible]="true"
  [blockScroll]="true"
  [appendTo]="chatBotSideBar"
>
  <ng-template pTemplate="header">
    <div class="header-container">
      <div class="pi pi-comments chat-comments-header-icon"></div>
      <div class="chat-header-title">Chatbot</div>
      <span
        class="custom-close-icon pi"
        [ngClass]="{ 'pi-window-minimize': isSideBarMaximized, 'pi-window-maximize': !isSideBarMaximized }"
        (click)="toggleSidebarSize()"
      ></span>
      <span class="custom-close-icon pi pi-minus" (click)="sidebarVisible = false"></span>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="chat-box-body">
      <div class="chat-logs">
        <ng-container *ngFor="let data of chatInputOutputMap">
          <div class="user-msg-icon" *ngIf="data.isChatbot === false">
            <span>Me</span>
          </div>
          <div class="user-msg" *ngIf="data.isChatbot === false">
            <span class="text">{{ data.msg }}</span>
          </div>

          <div class="self-msg-icon" *ngIf="data.isChatbot === true">
            <span class="pi pi-comments chatbot-log-header-icon"></span> <span class="chatbot-log-title">Chatbot</span>
          </div>
          <div class="self-msg" *ngIf="data.isChatbot === true">
            <p class="text" [innerHTML]="data.msg | boldText"></p>
          </div>
        </ng-container>

        <div class="self-msg-icon" *ngIf="isChatbotTyping === true">
          <span class="pi pi-comments chatbot-log-header-icon"></span> <span class="chatbot-log-title">Chatbot</span>
        </div>
        <div class="self-msg" *ngIf="isChatbotTyping === true">
          <span class="blink_self-msg-typing">. . .</span>
        </div>
      </div>
      <div *ngIf="needDisclaimer" class="disclaimer-action-container">
        <p-button label="I agree" i18n-label (onClick)="acknowledgeChatbotDisclaimer()"></p-button>
        <p-button
          label="I do NOT agree"
          i18n-label
          styleClass="p-button-secondary"
          (onClick)="sidebarVisible = false"
        ></p-button>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="chat-input" (keydown.enter)="getChatResponse($event)" *ngIf="!needDisclaimer">
      <div class="name-label">
        <svg class="icon icon-send" (click)="getChatResponse()">
          <use xlink:href="#icon-send"></use>
        </svg>
        <textarea
          type="text"
          class="chat-inputtext"
          pInputTextarea
          [(ngModel)]="chatInputValue"
          autocomplete="off"
          autofocus
          [autoResize]="true"
        ></textarea>
      </div>

      <svg class="spritesheet">
        <symbol id="icon-send" viewBox="0 0 24 24">
          <title>send</title>
          <path d="M6 12L3 21L21 12L3 3L6 12ZM6 12L12 12"></path>
        </symbol>
      </svg>
    </div>
  </ng-template>
</p-sidebar>
