export const DISCLAIMER_TEXT =
  'Introducing **SmartDialog** – your multilingual personal chatbot assistant for SmartDerivatives. Through this interface, you can ask any questions related to SmartD products/payoffs, as well as its features. You can also compare various payoffs, or even ask for the optimal payoff based on a certain situation. Should you have any questions, issues, or feedback, please email **dl.smartd.llm@bnpparibas.com**\r\n' +
  '\r\n- I WILL only use the provided web interface, and through professional device, to access SmartD Q&A' +
  '\r\n- I WILL ALWAYS double-check and verify information generated by the LLM' +
  '\r\n- I WILL ALWAYS maintain human oversight and have responsibility for any output shared (internally or externally) or decisions based on the output' +
  '\r\n- I am aware of the potential for hallucination (a phenomenon where LLM models generate incorrect or fabricated information that may not be presented in the input data or is not factual) and disinformation being generated' +
  '\r\n- I am aware of risks including accuracy, bias and discrimination' +
  '\r\n- I understand that all questions / prompts submitted as part of this Pilot will be logged' +
  '\r\n- I am aware that any misuse of the LLM to execute bookings or trading activities on SmartDerivatives could lead to a financial loss by following incorrect instructions and a corresponding operational incident' +
  '\r\n- I WILL seek advice and assistance from **dl.Smartd.llm@bnpparibas.com** when I have any doubt about what I am allowed to put in, take out or use from SmartDialog' +
  '\r\n- I WILL NOT ask the LLM any questions to generate new trade ideas' +
  '\r\n- I WILL NOT ask LLM an questions to generate any financial advice' +
  '\r\n- I WILL NOT rely solely on LLM output for decision making' +
  '\r\n- I WILL NOT (i) submit MNPI, secret, or personal (ii) data related to SSNDA and (iii) data related to transaction under NDA' +
  '\r\n- I WILL NOT use the LLM for any topic other than those defined within the framework of the use case i.e. Q&A on SmartDerivatives products/features' +
  '\r\n- I WILL NEVER accept generated content without proper verification' +
  '\r\n- I WILL NOT use LLM if I have any doubt about the security of data or information being input' +
  '\r\n- I WILL NEVER assume that all the output generated is factually correct';
